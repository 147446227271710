



































































































































import { FormBlock } from '@/app/components';
import { useAxios } from '@/app/composable';
import store from '@/app/store';
import { maxLengthValidator, requiredValidator } from '@/app/validators';
import { CheckIcon, ChevronLeftIcon, XIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, reactive, ref } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { RetrievalQueryAPI } from '../api';
import { RetrievalAccessibility, RetrievalAccessibilityType } from '../constants';
import { RetrievalQuery } from '../interfaces';

extend('required', requiredValidator);
extend('max', maxLengthValidator);

export default defineComponent({
    name: 'CreateRetrievalQuery',
    metaInfo() {
        return { title: `${(this as any).actionText} Retrieval Query` };
    },
    components: { ValidationObserver, ValidationProvider, FormBlock, OrbitSpinner, XIcon, CheckIcon, ChevronLeftIcon },
    props: {
        id: {
            type: String,
            required: false,
        },
        backTo: {
            type: String,
            default: 'retrieval',
        },
    },
    setup(props, { root }) {
        const { exec, loading, error } = useAxios(true);
        const retrievalQueryRef = ref<any>(null);
        const existingRetrievalQuery = ref<RetrievalQuery | null>(null);

        const retrievalQuery = reactive<{
            title?: string;
            description?: string;
            accessibility?: RetrievalAccessibility;
            assets?: number[];
            configuration?: any;
        }>({
            title: undefined,
            description: undefined,
            accessibility: undefined,
            assets: [],
            configuration: {},
        });

        const retrievalAccessibilityTypes = RetrievalAccessibilityType.all();
        retrievalQuery.accessibility = root.$route.params?.accessibility
            ? Object.values(RetrievalAccessibility).find((acc: string) => acc === root.$route.params?.accessibility)
            : undefined;
        const assets: number[] | undefined = root.$route.params?.assetId ? [Number(root.$route.params?.assetId)] : [];

        retrievalQuery.assets = assets;
        if (assets.length > 0)
            retrievalQuery.configuration.datasets = assets.map((assetId: number) => {
                return { id: `${assetId}`, fields: [] };
            });

        const isNew = computed(() => R.isNil(existingRetrievalQuery.value));
        const actionText = computed(() => (isNew.value ? 'Create' : 'Update'));
        const hasChanges = computed(
            () =>
                R.isNil(existingRetrievalQuery.value) ||
                existingRetrievalQuery.value.title !== retrievalQuery.title ||
                existingRetrievalQuery.value.description !== retrievalQuery.description ||
                existingRetrievalQuery.value.accessibility !== retrievalQuery.accessibility,
        );

        const goBackTo = () => {
            if (props.backTo === 'assets')
                root.$router.push({ name: props.backTo, query: store.state.queryParams.assets });
            else root.$router.push({ name: props.backTo });
        };

        const save = async () => {
            if (await retrievalQueryRef.value.validate()) {
                if (existingRetrievalQuery.value) {
                    exec(RetrievalQueryAPI.update(existingRetrievalQuery.value.id, retrievalQuery as any)).then(() => {
                        (root as any).$toastr.s(
                            'Your retrieval query has been updated successfully',
                            'Retrieval Query Updated',
                        );
                        goBackTo();
                    });
                } else {
                    exec(RetrievalQueryAPI.create(retrievalQuery as any)).then((res: any) => {
                        root.$router.push({ name: 'retrieval:configure', params: { id: res.data.id } });
                    });
                }
            }
        };

        // if there is an id then try to fetch it
        // and prefil the retrieval query
        if (props.id) {
            exec(RetrievalQueryAPI.get(props.id))
                .then((res: any) => {
                    existingRetrievalQuery.value = res.data;
                    retrievalQuery.title = res.data.title;
                    retrievalQuery.description = res.data.description;
                    retrievalQuery.accessibility = res.data.accessibility;
                    retrievalQuery.configuration = res.data.configuration;
                })
                .catch((e: any) => {
                    if (e?.response?.status === 403)
                        (root as any).$toastr.e(
                            'You do not have access to edit the specific retrieval query.',
                            'Access Forbidden!',
                        );
                    else if (e?.response?.data?.message) (root as any).$toastr.e(e.response.data.message);
                    goBackTo();
                });
        }

        return {
            error,
            isNew,
            loading,
            hasChanges,
            actionText,
            retrievalQuery,
            retrievalQueryRef,
            retrievalAccessibilityTypes,
            save,
            RetrievalAccessibility,
            goBackTo,
        };
    },
});
